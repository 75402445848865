import { Head, Page } from '@/components'

function App({ Component }) {
    return (
        <>
            <Head />
            <Page>
                <Component />
            </Page>
        </>
    )
}

App.getInitialProps = async ({ Component, ctx }) => {
    return {}
}

export default App
